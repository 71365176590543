<template>
  <div class="p-8 bg-blueGray-50 dark:bg-blueGray-900">
    <div class="flex items-center max-w-7xl mx-auto">
      <div class=" text-blueGray-700 dark:text-blueGray-300 w-1/3">
        <div class="flex items-center p-2">
          <div>
            <svg class="h-5" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20,15.5C18.8,15.5 17.5,15.3 16.4,14.9C16.3,14.9 16.2,14.9 16.1,14.9C15.8,14.9 15.6,15 15.4,15.2L13.2,17.4C10.4,15.9 8,13.6 6.6,10.8L8.8,8.6C9.1,8.3 9.2,7.9 9,7.6C8.7,6.5 8.5,5.2 8.5,4C8.5,3.5 8,3 7.5,3H4C3.5,3 3,3.5 3,4C3,13.4 10.6,21 20,21C20.5,21 21,20.5 21,20V16.5C21,16 20.5,15.5 20,15.5M5,5H6.5C6.6,5.9 6.8,6.8 7,7.6L5.8,8.8C5.4,7.6 5.1,6.3 5,5M19,19C17.7,18.9 16.4,18.6 15.2,18.2L16.4,17C17.2,17.2 18.1,17.4 19,17.4V19Z" />
            </svg>
          </div>
          <div class="pl-2">
            <a href="tel:+19412165352">(941) 216-5352</a>
          </div>
        </div>
        <div class="flex items-center p-2">
          <div>
            <svg class="h-5" viewBox="0 0 24 24">
              <path fill="currentColor" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
            </svg>
          </div>
          <div class="pl-2">
            derek@deltavcreative.com
          </div>
        </div>
      </div>
      <div class="text-sm text-center text-blueGray-400 dark:text-blueGray-600 w-1/3">
        Copyright © {{ new Date().getFullYear() }} DeltaV Creative
      </div>
      <div class="w-1/3 flex justify-end">
        <svg
            class="h-12"
            viewBox="0 0 280 245"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M192.058 179.423L229.891 245H269.904C277.602 245 282.413 236.667 278.564 230L220.633 129.66L192.058 179.423Z"
              class="text-lightBlue-700"
              fill="currentColor"
            />
            <path
              d="M209.07 109.633L180.5 159.388L111.377 39.5761L131.34 5.00002C135.189 -1.66665 144.811 -1.66667 148.66 5L209.07 109.633Z"
              class="text-lightBlue-700"
              fill="currentColor"
            />
            <path
              d="M154.402 245H206.801L180.54 199.481L154.402 245Z"
              class="text-lightBlue-500"
              fill="currentColor"
            />
            <path
              d="M168.982 179.446L140 229.918L70.93 109.633L99.8281 59.5798L168.982 179.446Z"
              class="text-lightBlue-500"
              fill="currentColor"
            />
            <path
              d="M59.3672 129.66L1.43596 230C-2.41304 236.667 2.39823 245 10.0962 245H125.598L59.3672 129.66Z"
              class="text-lightBlue-300"
              fill="currentColor"
            />
          </svg>
      </div>
    </div>
  </div>
</template>
